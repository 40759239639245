import React from "react";
import { Help } from "../../components/help/Help";

export const HelpPage = () => {
  return (
    <div>
      <Help />
    </div>
  );
};
