import React from 'react';
import classes from "./profileInfoPage.module.css"
import { Outlet } from 'react-router-dom';

export const ProfileInfoPage = () => {
  return (
    <Outlet/>
  )
}

