import React from "react";
import { Category } from "../../components/category/Category";

export const CategoryPage = () => {
  return (
    <div>
      <Category />
    </div>
  );
};
